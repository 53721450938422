import { createStore } from "redux"

const reducer = (state, action) => {
    switch (action.type) {
    case `SIGN_IN`:
      return Object.assign({}, state, {
        isLoggedin: true,
        jwt: action.jwt_token
      })
    case `ACREAGE_DISPLAY_STATE`:
      return Object.assign({}, state, {
        isPercentage: action.isPercentage,
      })
    case `PEREN_HA_STATE`:
      return Object.assign({}, state, {
        peren_ha: action.dataState,
      })
    case `NONCROP_HA_STATE`:
      return Object.assign({}, state, {
        noncrop_ha: action.dataState,
      })
    case `ONSET_HA_STATE`:
      return Object.assign({}, state, {
        onset_ha: action.dataState,
      })
    case `SELECT_SUBCONTINENT`:
      return Object.assign({}, state, {
        subcontinent: action.subcontinent,
        url: action.url,
        level_0: null,
        level_1: null,
        level_2: null,
        adm0: null,
        adm1: null,
        adm2: null,
        admin_level: null,
        data_section: 1,
        map_selection: true,
        infoCardIsVisible: false,
        isPercentage: false,
      })
    case `SELECT_ADMIN`:
      return Object.assign({}, state, {
        id: action.id,
        url: action.url,
        adm0: action.adm0,
        adm1: action.adm1,
        adm2: action.adm2,
        level_0: action.level_0,
        level_1: action.level_1,
        level_2: action.level_2,
        admin_level: action.admin_level,
        subcontinent: action.subcontinent,
        infoCardIsVisible: false,
        infoCardType: "acreage",
        isPercentage: false,
      })
    case `RESET_STATE`:
      return Object.assign({}, state, {
        url: null,
        level_0: null,
        level_1: null,
        level_2: null,
        adm0: null,
        adm1: null,
        adm2: null,
        admin_level: null,
        subcontinent: null,
        data_section: 1,
        map_selection: true,
        infoCardIsVisible: false,
        infoCardType: "acreage",
        isPercentage: false,
      })
    case `SELECT_TAB`:
      var infoCardType = ""
      if (action.id === 1) {
        infoCardType = "background"
      } else if (action.id === 2) {
        infoCardType = "acreage"
      } else if (action.id === 3) {
        infoCardType = "onset"
      }
      return Object.assign({}, state, {
        data_section: action.id,
        infoCardIsVisible: false,
        isPercentage: false,
        infoCardType,
      })
    case `CLOSE_INFOCARD`:
      return Object.assign({}, state, {
        infoCardIsVisible: false,
      })
    case `OPEN_INFOCARD`:
      return Object.assign({}, state, {
        infoCardIsVisible: true,
        infoCardType: action.contentType,
      })
    default:
      break
  }

  return state
}

const initialState = {
  id: null,
  url: null,
  level_0: null,
  level_1: null,
  level_2: null,
  adm0: null,
  adm1: null,
  adm2: null,
  admin_level: null,
  subcontinent: null,
  data_section: 1,
  peren_ha: true,
  onset_ha: true,
  noncrop_ha: true,
  map_selection: true, // toggle between map or list view for selecting county or region
  infoCardIsVisible: false,
  infoCardType: "acreage",
  isPercentage: false,
  jwt: "",
  isLoggedin: true
}

export default () => {
  return createStore(reducer, initialState)
}
